.weather-container{
  justify-content: space-between;
  justify-content:center;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  position: relative;
  width: 20vw;
  padding-bottom: 3%;
}

.weather {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 2vw;
  height: 22vh;
  align-items: center;
  padding-left: 12%;
  
}
.valueTemperatur {
  /* padding-right: 40%;
  padding-bottom: 0%;
  position: absolute;
  top: 3.6vh; */
  position: absolute;
  right: 90%;
  top: 20%;
}

.value {
  /* margin-top: -4vh;
 padding-left: 0%; */
  position: absolute;
  left: 90%;
  top: 20%;
}
.valuePrecipitation{
  /* padding-left: 40%;
  width: 4vw; */
  position: absolute;
  width: 4vw;
  top: 80%;
  left: 0%;
}

.weatherCloudAndPrecipitation-icon-container{
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 0%;
  padding-left: 50%;
  margin-top: 0px;
  width: 2vw; */
  position: absolute;
  right: 90%;
  top: 30%;
  width: 2vw;
  z-index: 1;
}

.wind-icon-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 80%;
  margin-top: -2vh; */
  position: absolute;
  top: -5%;
  left: 20%;
  z-index: 0;
}