.centered-photo-wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    /*top:50%;
    z-index: 100;
    left:50%;
    width: 43%;*/
}

.centered-photo-image {
    width: 100%;
}
.centered-photo-wrapper > .bi-x-circle-fill {
    position: absolute;
    top: -3%;
    left: 98.5%;
}



