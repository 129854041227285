.icon-wrapper {
    position: absolute;
    /*z-index: 2;*/
}

.icon-image {
    width: 100%;
}

/*ZOOM*/
.zoom {
    border-radius: 50px;
    border: 3px solid black;
}


.highlight {
    /*transition: all 250ms linear 2s;*/
    outline: 1vh rgba(255, 255, 255, .4) solid;
    filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
    border-radius: 50%;
}

