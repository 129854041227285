.animated {
    background: linear-gradient(to right, #3C95D6 70%, #338CCD);
    background-repeat: no-repeat;
    animation-duration: 1s;
    animation-fill-mode: none;

    position: absolute;
    width: 50vw;
    height: 10vh;
    left: 27vw;
    top: 87vh;
    z-index: 50;
    pointer-events: none;

 }
 @keyframes flash {
   0%, 20%, 40%, 60%, 80%, 100% {
       opacity: 1;
   }
   10%, 30%, 50%, 70%, 90% {
       opacity: 0;
   }
}
 
 .flash {
    animation-name: flash;
    animation: flash 3s ;

 }

 .flash {
    opacity: 0;
 }