

.punkt {
    position: absolute;
    width: 2vw;
    height: 2vw;
 
    background-color: red;
}

.firkant {
    border: 5px solid red;
    position: absolute;
}

.rotate-around-point {
    /*transform: rotate(45deg);*/
    transform-origin: center left;
}


.zoom-wrapper {
    position: absolute;
    width: 30vw;
    height: 30vh;
    /*z-index: 3;*/
}




.zoom-wrapper.lower {
    width: 44vh;
    height: 55vh;

    border-style: solid;
}

.zoom-wrapper.upper {
    width: 55vh;
    height: 44vh;
}

/*TREKANT*/

.pointer {
    position: absolute;
    z-index: 1;



    /*NY*/
    height: 100%;
    left: 0;
    width: 60%;
}

.pointer.upper {
    bottom: 0;
    width: 60%;
    
}

.pointer.lower {
    top: 0;
    height: 60%;
}

.pointer.left {
    right: 0;
}

.pointer.right {
    left: 0;
}

.pointer.lower.left {
    transform: rotate(180deg);
}

/*RUNDT BILLEDE*/

.zoom-image {
    position: absolute;
    border-radius: 50%;
    /*
    height: 30vh;
    width: 30vh;*/
    z-index: 3;

    /*NY*/
    right: 0;
}

.zoom-image.lower {
    bottom: 0;
}

.zoom-image.left {
    left: 0;
}

.zoom-image.right {
    right: 0;
}




