.video-player {
    position: absolute;
    top: 0;
}

.text-box {
    position: absolute;
    z-index: 1;
    width: 25vw;
    right: 0;
    padding: 1.5vw;
    text-align: center;
}