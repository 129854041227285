.documents .documents-container {
    box-sizing: border-box;
    float: left;
    padding: 5vh;
    width: 25vw;
    height: 100vh;
}


.documents .documents-container h2 {
    text-align: left;
    font-size: 2vh;
}

.documents .documents-container h2 img {
    display: inline-block;
    height: 1em;
    margin-right: 0.3em;
    margin-bottom: -0.15em;
}

.documents .documents-container h2.expanded:after {
    content: "";
    display: block;
    width: 15%;
    padding-top: 0.8vh;
    border-bottom: 1px solid white;
}

.documents .documents-container h2 .expand {
    float: right;
}


.documents .documents-container .document {
    text-align: left;
    padding: 1vh;
    font-weight: bold;
    margin-bottom: 1vh;
    color: white;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.7vh;
}

.documents .documents-container .document.active {
    color: black;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}


.documents .preview {
    box-sizing: border-box;
    float: right;
    padding: 5vh;
    width: 70vw;
    height: 100vh;
}

.documents .preview embed {
    width: 100%;
    height: 100%;
}

.category-item {
    margin-left: 0;
}

.category-item.depth-1 {
    margin-left: 20px;
}

.category-item.depth-2 {
    margin-left: 40px;
}
.category-item.depth-3 {
    margin-left: 40px;
}
.category-item.depth-4 {
    margin-left: 40px;
}


/* Add more as needed, depending on the maximum depth you expect */