

.outer-language-wrapper {
    /*border: 5px solid red;*/
    position: absolute;
    /*left: 7.5%;*/
    left: 10%;
    top: 90vh;
    text-align: left;
    z-index: 110;
}

.inner-language-wrapper {
    position: relative;
    left: 0;
}

.flag-wrapper {
    position: relative;
    display: inline-block;
    margin: 1vw 0.5vw;
}

.flag-image {
    border-radius: 50%;
    height: 3vh;
    width: 3vh;
}


.isCurrent {
    outline: 0.3vh rgba(255, 255, 255, .4) solid;
    filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
}