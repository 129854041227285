.wind {
    position: absolute;
    text-align: center;
    z-index: 10;

    /*TODO vibe slet igen
    width: 6.6vh;
    left: 85.5vw;
    top: 21vh;
    */
}

.speed {
    margin: auto;
    position: relative;
    color: white;
    font-size: 2vh;
    line-height: 2vh;
    bottom: -2vh;
    width: 250%;
    left: -75%;
    /*
    width: 150%; 
    left: -25%; -25 så den står i midten af wind-div fordi denne er 150 i width 
    */
}

.turn-right {
    transform: rotate(270deg);
}

.turn-left {
    transform: rotate(90deg);
}

.wind-bag-wrapper {
    width: 100% !important;
}

.wind-bag {
    width: 100%;
}



/*TODO Slet denne - den skal ikke bruges mere*/
.wind-error {
    font-size: 1.5vh;
    margin-top: 1vh;
}


.no-wind-bag {
    width: 130%;
    left:-15%;
    position: relative;
}


/*VIMPEL*/


@-webkit-keyframes swinging{
    0%{-webkit-transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);}
}
 
@keyframes swinging{

    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
}



 
.swingimage{
    
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
    animation: swinging 3.5s ease-in-out forwards infinite;
}




/*SKEW*/

.noget {
    animation: skew 3s infinite;
    transform: skew(20deg);
    animation-direction: alternate;
}

@keyframes skew {
    0% {
      transform: skewX(20deg);
    }
    100% {
      transform: skewX(-20deg);
    }
  }