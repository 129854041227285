
/*flashing*/
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.blink {
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/*
.blink {
    -webkit-animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
}
*/


/*fadein appear*/
@keyframes appear {
    0% {
        transform: translate(-50%, -50%);
        opacity: 0;
        width:0%;
    }
    100% {
        transform: translate(-50%, -50%);
        opacity: 1;
        width: 43%;
    }
}
.appear {
    animation: appear 0.5s;
    animation-iteration-count: 1;
}

@-webkit-keyframes appear {
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
        width:0%;
    }
    100% {
        transform: translate(-50%, -50%);
        opacity: 1;
        width: 43%;
    }
}