.player-btn {
    background:none;
    border: none;
    font-size: 4vh;
    color: white;
    width: 4vh;
    margin: 0 0.5vh;
}

.restart-btn{
    font-size: 3vh;
}

.vertically-align {  
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
} 

