video {
    width: 20vw;
}

.video-container {
    box-shadow: 0vw 0 0.5vw 0.3vw lightblue
}

.video-description {
    margin: 1vh 0 0 0;
}