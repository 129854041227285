/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100&display=swap'); Ørsteds officielle font*/


.hidden {
    visibility: hidden;
}

.label-wrapper {
    position: absolute;
    top: 0;
    margin: 1.5%;
    /*font-family: 'Noto Sans', sans-serif;*/
  
    font-family: orsted-font-light;
    letter-spacing: 0.1vw;

    
}

.dot {
    background-color: #2F6EBB; /*#629cce;*/ /* rgba(98, 156, 206)*/
    position: relative;
    border-radius: 50%;
}


.connecting-line {
    border-left: solid #2F6EBB; /*#629cce;*/
    position: absolute;
}

.label-box {
    text-align: center;
    position: relative;
    pointer-events: none;
}

.label {
    display: inline-block;
    padding: 0 1.5vh;
    color: rgba(255, 255, 255, 0.9);
    background-color: #2F6EBB; /*#629cce;*/
    font-size: 1.6vh;
    font-weight: 900;
}




/*

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-bold.woff") format('woff');
    font-weight: bold;
}

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-boldoblique.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-oblique.woff") format('woff');
    font-style: italic;
}
*/