@import url(https://fonts.googleapis.com/css?family=Sanchez|Fira+Sans);
html{
  /*background: url('../images/backdrop2.png');*/
  /*background: linear-gradient(-60deg,  rgb(121, 109, 155), rgb(190, 180, 210),rgb(121, 109, 155));*/
  /*background: linear-gradient(-30deg,  rgb(70, 8, 50), rgb(10, 10, 11),rgb(4, 4, 25));*/
  /*background: linear-gradient(-60deg,  rgb(110, 148, 101), rgb(180, 210, 187),rgb(110, 148, 101));*/
  /*background: linear-gradient(-40deg, rgb(147, 170, 78), rgb(194, 215, 129),rgb(129, 170, 41));
  background: linear-gradient(-20deg, rgb(85, 107, 24), rgb(255, 243, 105),rgb(78, 102, 17));
  background: linear-gradient(3deg, rgb(237, 135, 21), rgb(255, 246, 72),rgb(249, 145, 28));
  background: linear-gradient(3deg, rgb(70, 159, 127), rgb(135, 255, 230),rgb(65, 161, 154));
  background: linear-gradient(-70deg, rgb(0, 102, 143) 20%, rgb(0, 136, 191) 50%,rgb(0, 102, 143) 80%);
  /*background: linear-gradient(3deg, rgb(6, 0, 60), rgb(70, 144, 255),rgb(0, 106, 225));
  background: linear-gradient(3deg, rgb(0, 12, 80), rgb(80, 3, 255),rgb(222, 0, 255));*/
  /*
  background: -webkit-linear-gradient(160deg,#005441 20%,#006a52 50%,#005441 80%);
  background: linear-gradient(-70deg,#005441 20%,#006a52 50%,#005441 80%);
  */
  
  background: -webkit-linear-gradient(160deg,#3089ca 20%, #4099da 50%,#3089ca 80%);
  background: linear-gradient(-70deg,#3089ca 20%, #4099da 50%,#3089ca 80%);
  
  background-size: cover;
  color: white;
  box-sizing: border-box;
}

body{
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  margin: 0;
}

h3{
  font-size: 2vh;
  line-height: 2vh;
}

p{
  font-size: 1vh;
  line-height: 1vh;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.content{
  float: left;
}


.slide-show-2{
  left: 33vw;
}
.slide-show-1 {
  left: 7vw;
}

.slide-show {
  /*bottom: 3vh;*/
  position: absolute;   
  width: 44.5vh;/*23%;*/
  height: 25vh;/*25%;*/
  top: 71.7%;
  overflow: hidden;
  border-radius: 0.5vh;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.slide-show .slide img{
  width: 100%;
}


.slides{
  float: left;
  width: 95%;
  height: 33%;
}

.slides .temp-slide{
  box-sizing: border-box;
  height: 100%;
  width: 33.3%;
  padding: 2%;
  padding-right: 3%;
  padding-left: 3%;
  float: left;
}

.slides .temp-slide .slide{
  background: olive;
  width: 100%;
  height: 100%;
}

.slides .temp-slide .slide img{
  width: 100%;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown{
  opacity: 1;
  width: 40px;
}

.numInputWrapper span.arrowUp::after, .numInputWrapper span.arrowDown::after{
  left: 37%;
}

.flatpickr-time .numInputWrapper input{
  padding-right: 33px;
}

.text-muted{
  color: #b5c0ce;
}

.text-sm{
  font-size: 0.8em;
}

.persons{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.persons .person{
  position: relative;
  width: 3.7vw;
  margin-right: 0.8vw;
  margin-bottom: 1vw;
}

.persons .person img{
  width: 100%;
  border-radius: 100%;
}

.persons .person .person-name{
  position: absolute;
  font-size: 0.45vw;
  line-height: 0.45vw;
  bottom: -0.8vh;
  text-align: center;
  left: -20%;
  right: -20%;
}

@font-face {
    font-family: "orsted-font-light";
    src: url(/static/media/OrstedSans-Light.40d7d1f4.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-black";
    src: url(/static/media/OrstedSans-Black.919c32be.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-black-italic";
    src: url(/static/media/OrstedSans-BlackItalic.99c2dd72.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-bold";
    src: url(/static/media/OrstedSans-Bold.0359cd6e.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-bold-italic";
    src: url(/static/media/OrstedSans-BoldItalic.1cc81c74.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-italic";
    src: url(/static/media/OrstedSans-Italic.065116f3.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-light-italic";
    src: url(/static/media/OrstedSans-LightItalic.a47a5fb7.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-regular";
    src: url(/static/media/OrstedSans-Regular.ce818dbc.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-semi-bold";
    src: url(/static/media/OrstedSans-SemiBoldText.b734b493.woff) format('woff');
}
.menu{
    box-sizing: border-box;
    float: left;
    width: 5%;
    height: 100%;
    background: #4099da;
    padding: 1vh 0;
    font-size: 2.5vh;
    /*text-shadow: 1px 1px 2px black;*/
}
  
.menu .menu-point-container{
    position: relative;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu-point{
    height: 5vw;
    /*border-top: 1px solid white;*/
    line-height: 5vw;
}

.menu-point a{
    text-decoration: none;
    color: white;
    font-weight: 900;
}

.menu-point:last-child{
    /*border-bottom: 1px solid white;*/
}

.menu img{
    box-sizing: border-box;
    width: 100%;
    padding: 1.8vh;
}
.video
{   
    top: 10vh;
    left: 15vw;
    position: absolute;
    padding-left: 3%;
    z-index: 0;
    -webkit-filter: drop-shadow(0 0 0.75rem rgb(251, 255, 253));
            filter: drop-shadow(0 0 0.75rem rgb(251, 255, 253));

}
.video-on-top {
    z-index: 100;
}
.induction-controls {
    height: 3.4vh;
    z-index: 100;
    left: 5vw;
    position: relative;
    width:60vw;
    font-size: 2vw;
    padding-left: 3%;    
    margin: 0 17.5vw; /*17.5 fordi auto virker ikke når der er left 5vw. fordi width = 60vw + left 5 vw == 65 --> 100 - 65 == 35 --> 35 / 2 == 17.5*/
    padding: 1vh;

    border-radius: 1.5vw;
    border: 2px solid rgba(255, 255, 255, .4);
}

.controls-inner-wrapper{
    width: 10vw;
    display: inline-block;
    position: absolute;
    left: -1vh;
    height: 60%;
}

.restart-inner-wrapper{
    position: absolute;
    left: 100vh;
    top: 20%;
    height: 60%;
}

.chapter-title {
    text-align: left;
    text-align: initial;
    margin-left: 10vw;
    margin-right: 4vw;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: orsted-font-regular;
    font-size: 80%;
}


.center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.weather-popup{
    position: absolute;
    background-color: white;
    z-index: 150;
    width: 70vw;
    height: 75vh;
    left: 15vw;
    top: 12.5vh;
    border-style:solid;
    border-width: 10px;
    border-radius: 76px;
    border-color: rgba(51, 51, 51, 0.911);
}


/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100&display=swap'); Ørsteds officielle font*/


.hidden {
    visibility: hidden;
}

.label-wrapper {
    position: absolute;
    top: 0;
    margin: 1.5%;
    /*font-family: 'Noto Sans', sans-serif;*/
  
    font-family: orsted-font-light;
    letter-spacing: 0.1vw;

    
}

.dot {
    background-color: #2F6EBB; /*#629cce;*/ /* rgba(98, 156, 206)*/
    position: relative;
    border-radius: 50%;
}


.connecting-line {
    border-left: solid #2F6EBB; /*#629cce;*/
    position: absolute;
}

.label-box {
    text-align: center;
    position: relative;
    pointer-events: none;
}

.label {
    display: inline-block;
    padding: 0 1.5vh;
    color: rgba(255, 255, 255, 0.9);
    background-color: #2F6EBB; /*#629cce;*/
    font-size: 1.6vh;
    font-weight: 900;
}




/*

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-bold.woff") format('woff');
    font-weight: bold;
}

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-boldoblique.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "myfont";
    src: url("fonts/awesome-font-oblique.woff") format('woff');
    font-style: italic;
}
*/
.player-btn {
    background:none;
    border: none;
    font-size: 4vh;
    color: white;
    width: 4vh;
    margin: 0 0.5vh;
}

.restart-btn{
    font-size: 3vh;
}

.vertically-align {  
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
} 




.outer-language-wrapper {
    /*border: 5px solid red;*/
    position: absolute;
    /*left: 7.5%;*/
    left: 10%;
    top: 90vh;
    text-align: left;
    z-index: 110;
}

.inner-language-wrapper {
    position: relative;
    left: 0;
}

.flag-wrapper {
    position: relative;
    display: inline-block;
    margin: 1vw 0.5vw;
}

.flag-image {
    border-radius: 50%;
    height: 3vh;
    width: 3vh;
}


.isCurrent {
    outline: 0.3vh rgba(255, 255, 255, .4) solid;
    -webkit-filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
            filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
}
.icon-wrapper {
    position: absolute;
    /*z-index: 2;*/
}

.icon-image {
    width: 100%;
}

/*ZOOM*/
.zoom {
    border-radius: 50px;
    border: 3px solid black;
}


.highlight {
    /*transition: all 250ms linear 2s;*/
    outline: 1vh rgba(255, 255, 255, .4) solid;
    -webkit-filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
            filter: drop-shadow(0 0 0.4rem rgb(251, 255, 253));
    border-radius: 50%;
}



/*flashing*/
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.blink {
    -webkit-animation: blink 1s;
            animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/*
.blink {
    -webkit-animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
}
*/


/*fadein appear*/
@keyframes appear {
    0% {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        opacity: 0;
        width:0%;
    }
    100% {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        opacity: 1;
        width: 43%;
    }
}
.appear {
    -webkit-animation: appear 0.5s;
            animation: appear 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

@-webkit-keyframes appear {
    0% {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        opacity: 1;
        width:0%;
    }
    100% {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        opacity: 1;
        width: 43%;
    }
}
.centered-photo-wrapper {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /*top:50%;
    z-index: 100;
    left:50%;
    width: 43%;*/
}

.centered-photo-image {
    width: 100%;
}
.centered-photo-wrapper > .bi-x-circle-fill {
    position: absolute;
    top: -3%;
    left: 98.5%;
}






.punkt {
    position: absolute;
    width: 2vw;
    height: 2vw;
 
    background-color: red;
}

.firkant {
    border: 5px solid red;
    position: absolute;
}

.rotate-around-point {
    /*transform: rotate(45deg);*/
    -webkit-transform-origin: center left;
            transform-origin: center left;
}


.zoom-wrapper {
    position: absolute;
    width: 30vw;
    height: 30vh;
    /*z-index: 3;*/
}




.zoom-wrapper.lower {
    width: 44vh;
    height: 55vh;

    border-style: solid;
}

.zoom-wrapper.upper {
    width: 55vh;
    height: 44vh;
}

/*TREKANT*/

.pointer {
    position: absolute;
    z-index: 1;



    /*NY*/
    height: 100%;
    left: 0;
    width: 60%;
}

.pointer.upper {
    bottom: 0;
    width: 60%;
    
}

.pointer.lower {
    top: 0;
    height: 60%;
}

.pointer.left {
    right: 0;
}

.pointer.right {
    left: 0;
}

.pointer.lower.left {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/*RUNDT BILLEDE*/

.zoom-image {
    position: absolute;
    border-radius: 50%;
    /*
    height: 30vh;
    width: 30vh;*/
    z-index: 3;

    /*NY*/
    right: 0;
}

.zoom-image.lower {
    bottom: 0;
}

.zoom-image.left {
    left: 0;
}

.zoom-image.right {
    right: 0;
}





.wind {
    position: absolute;
    text-align: center;
    z-index: 10;

    /*TODO vibe slet igen
    width: 6.6vh;
    left: 85.5vw;
    top: 21vh;
    */
}

.speed {
    margin: auto;
    position: relative;
    color: white;
    font-size: 2vh;
    line-height: 2vh;
    bottom: -2vh;
    width: 250%;
    left: -75%;
    /*
    width: 150%; 
    left: -25%; -25 så den står i midten af wind-div fordi denne er 150 i width 
    */
}

.turn-right {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}

.turn-left {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.wind-bag-wrapper {
    width: 100% !important;
}

.wind-bag {
    width: 100%;
}



/*TODO Slet denne - den skal ikke bruges mere*/
.wind-error {
    font-size: 1.5vh;
    margin-top: 1vh;
}


.no-wind-bag {
    width: 130%;
    left:-15%;
    position: relative;
}


/*VIMPEL*/


@-webkit-keyframes swinging{
    0%{-webkit-transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);}
}
 
@keyframes swinging{

    0%{-webkit-transform: rotate(10deg);transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg);transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);transform: rotate(10deg);}
}



 
.swingimage{
    
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
    animation: swinging 3.5s ease-in-out forwards infinite;
}




/*SKEW*/

.noget {
    -webkit-animation: skew 3s infinite;
            animation: skew 3s infinite;
    -webkit-transform: skew(20deg);
            transform: skew(20deg);
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

@-webkit-keyframes skew {
    0% {
      -webkit-transform: skewX(20deg);
              transform: skewX(20deg);
    }
    100% {
      -webkit-transform: skewX(-20deg);
              transform: skewX(-20deg);
    }
  }

@keyframes skew {
    0% {
      -webkit-transform: skewX(20deg);
              transform: skewX(20deg);
    }
    100% {
      -webkit-transform: skewX(-20deg);
              transform: skewX(-20deg);
    }
  }
.compass-wrapper {
    position: absolute;
    z-index: 5;
}

.compass-image {
    width: 100%;
}

.weather-container{
  justify-content: space-between;
  justify-content:center;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  position: relative;
  width: 20vw;
  padding-bottom: 3%;
}

.weather {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 2vw;
  height: 22vh;
  align-items: center;
  padding-left: 12%;
  
}
.valueTemperatur {
  /* padding-right: 40%;
  padding-bottom: 0%;
  position: absolute;
  top: 3.6vh; */
  position: absolute;
  right: 90%;
  top: 20%;
}

.value {
  /* margin-top: -4vh;
 padding-left: 0%; */
  position: absolute;
  left: 90%;
  top: 20%;
}
.valuePrecipitation{
  /* padding-left: 40%;
  width: 4vw; */
  position: absolute;
  width: 4vw;
  top: 80%;
  left: 0%;
}

.weatherCloudAndPrecipitation-icon-container{
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 0%;
  padding-left: 50%;
  margin-top: 0px;
  width: 2vw; */
  position: absolute;
  right: 90%;
  top: 30%;
  width: 2vw;
  z-index: 1;
}

.wind-icon-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 80%;
  margin-top: -2vh; */
  position: absolute;
  top: -5%;
  left: 20%;
  z-index: 0;
}
/* Weather.css */

.Weather {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;

  height: 75vh;
  width: 70vw;

  border-width: 20px;
  border-radius: 70px;
  border-color: black;

}
.weatherCloudAndPrecipitation-icon {
  width: 6vw;
  padding-top: 70%;
}
.wind-icon {
  width: 6vw;
  padding-top: 80%;
}
.vejrDataFraDmi{
  font-size: medium;
  padding-top: 188%;
}
.documents .documents-container {
    box-sizing: border-box;
    float: left;
    padding: 5vh;
    width: 25vw;
    height: 100vh;
}


.documents .documents-container h2 {
    text-align: left;
    font-size: 2vh;
}

.documents .documents-container h2 img {
    display: inline-block;
    height: 1em;
    margin-right: 0.3em;
    margin-bottom: -0.15em;
}

.documents .documents-container h2.expanded:after {
    content: "";
    display: block;
    width: 15%;
    padding-top: 0.8vh;
    border-bottom: 1px solid white;
}

.documents .documents-container h2 .expand {
    float: right;
}


.documents .documents-container .document {
    text-align: left;
    padding: 1vh;
    font-weight: bold;
    margin-bottom: 1vh;
    color: white;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.7vh;
}

.documents .documents-container .document.active {
    color: black;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}


.documents .preview {
    box-sizing: border-box;
    float: right;
    padding: 5vh;
    width: 70vw;
    height: 100vh;
}

.documents .preview embed {
    width: 100%;
    height: 100%;
}

.category-item {
    margin-left: 0;
}

.category-item.depth-1 {
    margin-left: 20px;
}

.category-item.depth-2 {
    margin-left: 40px;
}
.category-item.depth-3 {
    margin-left: 40px;
}
.category-item.depth-4 {
    margin-left: 40px;
}


/* Add more as needed, depending on the maximum depth you expect */
.App .static-map{
    width: 100%;
    height: 100%;
}
.App .static-map img{
    /*width: 90vw;*/
    height: 84vh;
    margin: 1.5%;
}
.animated {
    background: -webkit-gradient(linear, left top, right top, color-stop(70%, #3C95D6), to(#338CCD));
    background: -webkit-linear-gradient(left, #3C95D6 70%, #338CCD);
    background: linear-gradient(to right, #3C95D6 70%, #338CCD);
    background-repeat: no-repeat;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: none;
            animation-fill-mode: none;

    position: absolute;
    width: 50vw;
    height: 10vh;
    left: 27vw;
    top: 87vh;
    z-index: 50;
    pointer-events: none;

 }
 @-webkit-keyframes flash {
   0%, 20%, 40%, 60%, 80%, 100% {
       opacity: 1;
   }
   10%, 30%, 50%, 70%, 90% {
       opacity: 0;
   }
}
 @keyframes flash {
   0%, 20%, 40%, 60%, 80%, 100% {
       opacity: 1;
   }
   10%, 30%, 50%, 70%, 90% {
       opacity: 0;
   }
}
 
 .flash {
    -webkit-animation-name: flash;
            animation-name: flash;
    -webkit-animation: flash 3s ;
            animation: flash 3s ;

 }

 .flash {
    opacity: 0;
 }
.video-player {
    position: absolute;
    top: 0;
}

.text-box {
    position: absolute;
    z-index: 1;
    width: 25vw;
    right: 0;
    padding: 1.5vw;
    text-align: center;
}
.menu .instruction {
    position: absolute;
    z-index: 0;
}

.column {
    float: left;
    width: 20vw;
    height: 25vh;
    padding: 1vw;
}

.row {
    position: absolute;
    width: 91vw;
    left: 5vw;
    padding: 2vw;
    
    overflow: auto;
    height: 93vh;
}
.row::-webkit-scrollbar {
    display: none;
}

.row:after {
    display: table;
}

.InstructionPlayer-container {
    position: fixed;
    width: 70vw;
    height: 70vh;
    left: 15vw;
    top: 15vh;
    z-index: 2;
}

.outer-video-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}
video {
    width: 20vw;
}

.video-container {
    box-shadow: 0vw 0 0.5vw 0.3vw lightblue
}

.video-description {
    margin: 1vh 0 0 0;
}
