.menu .instruction {
    position: absolute;
    z-index: 0;
}

.column {
    float: left;
    width: 20vw;
    height: 25vh;
    padding: 1vw;
}

.row {
    position: absolute;
    width: 91vw;
    left: 5vw;
    padding: 2vw;
    
    overflow: auto;
    height: 93vh;
}
.row::-webkit-scrollbar {
    display: none;
}

.row:after {
    display: table;
}

.InstructionPlayer-container {
    position: fixed;
    width: 70vw;
    height: 70vh;
    left: 15vw;
    top: 15vh;
    z-index: 2;
}

.outer-video-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    backdrop-filter: blur(3px);
}